body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sidebarLink {
  color: var(--gray);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 0px 15px;
}

.sidebarLinkActive {
  color: var(--white);
}

.sidebarLink:hover {
  color: var(--primary-soft);
  text-decoration: none;
}

.sidebarLink > svg {
  font-size: 25px;
  width: 25px;
  height: 25px;
}

.highlight {
  background-color: yellow;
}

.slider {
  display: flex;
  justify-content: space-between;
  border-bottom: 3px solid rgba(67, 67, 67, 0.15);
  padding-bottom: 0;
}

.slider > div {
  flex-grow: 1;
  text-align: center;
  text-decoration: none;
  padding-bottom: 10px; 
  cursor: pointer;
}

.slider > .active::after {
  content: '';
  display: block;
  width: 100%;
  border-bottom: 3px solid rgb(0,183,145);
  transform: translateY(13px);
}